import React from 'react';
import PropTypes from 'prop-types';
import {kebabCase} from 'lodash';
import {graphql, Link} from 'gatsby';

import Layout from '../components/Layout';
import Content, {HTMLContent} from '../components/Content';

const TagList = props => <>
    {props.tags && props.tags.length ? (
        <div style={{marginTop: `2rem`}}>
            <h4>{props.label}</h4>
            <ul className="taglist">
                {props.tags.map((tag) => (
                    <li key={tag.slug}>
                        <Link to={`${props.path}/${kebabCase(tag.slug)}/`} className="button">{tag.label}</Link>
                    </li>
                ))}
            </ul>
        </div>
    ) : null}
</>;

TagList.propTypes = {
    tags: PropTypes.arrayOf(PropTypes.object),
    label: PropTypes.string,
    path: PropTypes.string
};
export const BlogPostTemplate = ({
                                     content,
                                     contentComponent,
                                     places,
                                     cities,
                                     title,
                                 }) => {
    const PageContent = contentComponent || Content;

    return (
        <section className="section">
            <div className="container content">
                <div className="columns">
                    <div className="column is-10 is-offset-1">
                        <h1 className="title is-size-3">
                            {title}
                        </h1>
                        <PageContent className="content has-text-justified article-content" content={content}/>
                        <TagList tags={places} label={"Интересные места"} path="/places"/>
                        <TagList tags={cities} label={"Города"} path="/cities"/>
                    </div>
                </div>
            </div>
        </section>
    )
}

BlogPostTemplate.propTypes = {
    content: PropTypes.node.isRequired,
    contentComponent: PropTypes.func,
    title: PropTypes.string,
}

const BlogPost = ({data, pageContext}) => {
    const {markdownRemark: post} = data;

    return (
        <Layout title={post.frontmatter.title} description={post.frontmatter.description}>
            <BlogPostTemplate
                content={post.html}
                contentComponent={HTMLContent}
                places={pageContext.places}
                cities={pageContext.cities}
                title={post.frontmatter.title}
            />
        </Layout>
    )
}

BlogPost.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.object,
    }),
}

export default BlogPost

export const pageQuery = graphql`
    query BlogPostByID($id: String!) {
        markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                title
                description
            }
        }
    }
`
